<template>
	<Fragment>
		<div class="section pb-56 mb-56 border-bottom">
			<UserListItem :userList="customerUserInfo.userList" @detail="openCustomerUseDetails"></UserListItem>
			<div class="pagination-group">
				<!--wrap_paging-->
				<BasePagination
					:per-page="parseInt(customerUserInfo.size)"
					:total="customerUserInfo.total"
					@page="getCustomerUserInfo"
				></BasePagination>
				<div class="select mt-20" v-if="windowWidth > 720">
					<select v-model="customerUserInfo.size" @change="getCustomerUserInfoByChangedPageSize">
						<option selected="selected">10</option>
						<option>30</option>
						<option>50</option>
					</select>
				</div>
			</div>
		</div>
	</Fragment>
</template>

<script>
import { DEFAULT_MODAL_STYLE } from '@/utils/constants';
import CustomerUserDetails from '@/components/apply/serviceApplyInfo/CustomerUserDetails';
import UserListItem from '@/components/user/UserListItem';
import BasePagination from '@/components/common/BasePagination';
import mixinsAlert from '@/components/common/mixinsAlert';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';

export default {
	props: ['customerUserInfo'],
	components: {
		UserListItem,
		BasePagination,
	},
	data() {
		return {
			windowWidth: window.innerWidth,
		};
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	mixins: [mixinsAlert, mixinsErrCodeObserver],
	methods: {
		openCustomerUseDetails(params) {
			let modalName = 'customerUserDetails';
			this.$modal.show(
				CustomerUserDetails,
				{
					email: params.email,
					username: params.username,
					role: params.role,
					modalName,
					updateUserRole: (email, role) => {
						if (role === 'SUPER ADMIN') {
							this.$modal.hide(modalName);
							return this.showConfirmUpdateRoleType(email, role);
						}
						this.dispatchUpdateUserRole(email, role);
					},
					showDeleteConfirmModal: (email, role) => {
						this.showDeleteConfirmModal(email, role);
						this.$modal.hide(modalName);
					},
				},
				Object.assign({}, DEFAULT_MODAL_STYLE, {
					name: 'customerUserDetails',
				}),
			);
		},
		dispatchUpdateUserRole(email, role) {
			const response = this.$store.dispatch('UPDATE_CUSTOMER_USER_ROLE', {
				email,
				role,
			});

			response
				.then((res) => {
					this.$store.dispatch('GET_CUSTOMER_USER_INFO', {
						page: this.$store.state.apply.customerUserInfo.page,
						size: this.$store.state.apply.customerUserInfo.size,
					});
					this.$store.dispatch('GET_CUSTOMER_USER_HISTORY_INFO', {
						page: this.$store.getters.getCustomerUserHistoryInfo.page,
						size: this.$store.getters.getCustomerUserHistoryInfo.size,
					});
					this.$toasted.show(
						role !== 'NORMAL' ? '저장되었습니다.' : '해당 계정을 다우기술(고객사명)의 사용자에서 제외했습니다.',
					);
				})
				.catch((e) => {
					this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.$toasted.show);
				});
		},
		showDeleteConfirmModal(email, role) {
			this.alert({
				contents: '귀사의 사용자에서 제외하시겠습니까?<br />계정은 삭제되지 않습니다.',
				buttons: [
					{
						text: '제외',
						callback: () => {
							this.dispatchUpdateUserRole(email, role);
						},
					},
					{},
				],
			});
		},
		showConfirmUpdateRoleType(email, role) {
			this.alert({
				contents:
					'해당계정의 권한을 SUPER ADMIN으로 변경하시겠습니까?\n변경 시, 기존 SUPER ADMIN 계정은 ADMIN으로 권한이 변경됩니다',
				buttons: [
					{
						text: '확인',
						callback: () => {
							this.dispatchUpdateUserRole(email, role);
						},
					},
					{},
				],
			});
		},
		async getCustomerUserInfo(pageInfo) {
			this.customerUserInfo.page = pageInfo.page;
			await this.$store
				.dispatch('GET_CUSTOMER_USER_INFO', {
					page: pageInfo.currentPage - 1,
					size: pageInfo.currentPerpage,
				})
				.catch((e) => {
					this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.$toasted.show);
				});
		},
		async getCustomerUserInfoByChangedPageSize() {
			await this.$store.dispatch('GET_CUSTOMER_USER_INFO', {
				page: 0,
				size: this.customerUserInfo.size,
			});
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>

<style scoped></style>
