<template>
	<div>
		<table class="basic-table">
			<thead>
				<tr>
					<th class="">
						<span class="txt">이름</span>
					</th>
					<th class="">
						<span class="txt">이메일</span>
					</th>
					<th class="text-center">
						<span class="txt">권한</span>
					</th>
					<th class="state text-center">
						<span class="txt">상태</span>
					</th>
					<th class="text-center">
						<span class="txt">최근로그인</span>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr @click="$emit('detail', user)" v-for="(user, index) in userList" :key="index">
					<td class="">
						<span class="txt">{{ maskedUsername(user.username) }}</span>
					</td>
					<td class="">
						<span class="txt">{{ maskedEmail(user.email) }}</span>
					</td>
					<td class="text-center">
						<span class="txt">{{ user.role }}</span>
					</td>
					<td class="state text-center">
						<span class="txt">{{ user.state }}</span>
					</td>
					<td class="text-center">
						<span class="txt">{{ user.lastLoginDate }}</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { masking } from '@/utils/utils';

export default {
	props: ['userList'],
	methods: {
		// openDetails() {
		// 	this.emit('open', params);
		// },
		maskedUsername(username) {
			return masking.username(username);
		},
		maskedEmail(email) {
			return masking.email(email);
		},
	},
};
</script>
<style scoped></style>
