<template>
	<div class="section">
		<h3 class="title">히스토리</h3>
		<div class="contents timeline">
			<div class="group column">
				<!-- com_timeline -> group column -->
				<div class="group row" v-for="(history, index) in userHistoryInfo.historyList" :key="index">
					<!-- tb_row -> .group.row -->
					<div class="cell date">
						<!-- tb_cell -> X -->
						<!-- <span class="">  wrap_date -> X -->
						<span class="txt">{{ history.createdAt }}</span>
						<!-- </span> -->
					</div>
					<div class="cell name">
						<!-- tb_cell -> X -->
						<span class="txt">{{ history.updateBy }}</span>
					</div>
					<div class="cell state">
						<!-- tb_cell -> X -->
						<span class="tag">{{ history.historyType }}</span>
						<!-- label_state -->
					</div>
					<div class="cell name">
						<!-- tb_cell -> X -->
						<span class="txt">{{ history.targetName }}</span>
					</div>
					<div class="cell value">
						<!-- tb_cell -> X -->
						<span class="txt">{{ history.changedContext }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- pagenation -->
		<!-- <div class="">  wrap_function -->
		<div class="pagination-group">
			<!--wrap_paging-->
			<BasePagination
				:per-page="parseInt(userHistoryInfo.size)"
				:total="userHistoryInfo.total"
				@page="getHistoryInfoByCurrentPage"
			></BasePagination>
			<!-- 필요시 select 사용 -->
			<div class="select" v-if="windowWidth > 720">
				<select v-model="userHistoryInfo.size" @change="getHistoryInfoByChangedPageSize">
					<option selected="selected">10</option>
					<option>30</option>
					<option>50</option>
				</select>
			</div>
			<!-- </div> -->
		</div>
		<!-- </div> -->
	</div>
</template>

<script>
import BasePagination from '@/components/common/BasePagination';

export default {
	components: { BasePagination },
	props: ['userHistoryInfo'],
	data() {
		return {
			windowWidth: window.innerWidth,
		};
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	methods: {
		async getHistoryInfoByCurrentPage(pageInfo) {
			await this.$store.dispatch('GET_CUSTOMER_USER_HISTORY_INFO', {
				page: pageInfo.currentPage - 1,
				size: pageInfo.currentPerpage,
			});
		},
		async getHistoryInfoByChangedPageSize() {
			await this.$store.dispatch('GET_CUSTOMER_USER_HISTORY_INFO', {
				page: 0,
				size: this.userHistoryInfo.size,
			});
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
	},
};
</script>

<style scoped></style>
