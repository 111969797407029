<template>
	<Fragment>
		<div class="header">
			<!-- // .lay_header -> .header -->
			<h1 class="heading-3-b">사용자 상세보기</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<!--  contents-wrap  -->
		<div class="contents-wrap">
			<ul class="inp-list">
				<li>
					<div class="group-inp">
						<label class="label">이름</label>
						<p class="value">{{ usernameInput }}</p>
					</div>
				</li>
				<li>
					<div class="group-inp">
						<label class="label">이메일 주소</label>
						<p class="value">{{ emailInput }}</p>
					</div>
				</li>
				<li>
					<div class="group-inp">
						<label class="label">권한</label>
						<div class="select">
							<select v-model="roleInput" disabled>
								<option
									v-for="(item, index) in $store.state.apply.customerUserInfo.typeList"
									:key="index"
									:value="item"
								>
									{{ item }}
								</option>
							</select>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<!--//content end-->
		<div class="">
			<div class="btn-area group-btn" v-if="$store.state.login.currentUser.accountRoleType === 'SUPER ADMIN'">
				<button class="line" @click="deleteCustomerUserRole">제외</button>
				<button class="solid" @click="updateCustomerUserRole">저장</button>
			</div>
		</div>
	</Fragment>
</template>
<script>
export default {
	props: [
		'username',
		'email',
		'role',
		'state',
		'lastLoginDate',
		'modalName',
		'updateUserRole',
		'showDeleteConfirmModal',
	],
	data() {
		return {
			usernameInput: '',
			emailInput: '',
			roleInput: '',
		};
	},
	created() {
		this.usernameInput = this.username;
		this.emailInput = this.email;
		this.roleInput = this.role === 'NORMAL' ? 'SUPER ADMIN' : this.role;
	},
	methods: {
		updateCustomerUserRole(e) {
			if (this.equalsLoginEmail(e)) {
				return;
			}
			this.updateUserRole(this.emailInput, this.roleInput);
		},
		deleteCustomerUserRole(e) {
			if (this.equalsLoginEmail(e)) {
				return;
			}
			this.showDeleteConfirmModal(this.emailInput, 'NORMAL');
		},
		equalsLoginEmail(e) {
			if (this.emailInput === this.$store.state.login.currentUser.email) {
				this.$toasted.show('본인계정의 권한을 변경할 수 없습니다.');
				return true;
			}
			return false;
		},
		handleClose(e) {
			e.preventDefault();
			this.$modal.hide(this.modalName);
		},
	},
};
</script>

<style scoped></style>
