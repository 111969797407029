<template>
	<div class="container">
		<!-- contents area -->
		<div class="body-contents history">
			<h2 class="heading-1-b">사용자 초대/관리</h2>
			<div class="util right">
				<button class="icon-text line medium" @click="openCustomerUserApply">
					<i class="ic-16 ic-add-bold"></i>
					사용자 초대
				</button>
			</div>
			<div class="section-wrap">
				<!-- 사용자 관리 리스트 -->
				<CustomerUserList :customerUserInfo="$store.getters.getCustomerUserInfo"></CustomerUserList>
				<!-- pagenation -->
				<!-- <div class="">  wrap_function -->
				<!-- </div> -->
				<!-- 히스토리 -->
				<CustomerUserHistory :userHistoryInfo="$store.getters.getCustomerUserHistoryInfo"></CustomerUserHistory>
			</div>
		</div>
	</div>
</template>

<script>
import { DEFAULT_MODAL_STYLE } from '@/utils/constants';
import CustomerUserApply from '@/components/apply/serviceApplyInfo/CustomerUserApply';
import CustomerUserList from '@/components/apply/serviceApplyInfo/CustomerUserList';
import CustomerUserHistory from '@/components/apply/serviceApplyInfo/CustomerUserHistory';
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';

export default {
	name: 'UserManagement',
	components: {
		CustomerUserList,
		CustomerUserHistory,
	},
	created() {
		this.fetchCustomerUser();
		this.fetchCustomerUserHistory();
	},
	mixins: [mixinsErrCodeObserver],
	methods: {
		openCustomerUserApply() {
			this.$modal.show(
				CustomerUserApply,
				{
					modalName: 'customerUserApply',
				},
				Object.assign({}, DEFAULT_MODAL_STYLE, { name: 'customerUserApply' }),
			);
		},
		async fetchCustomerUser() {
			await this.$store
				.dispatch('GET_CUSTOMER_USER_INFO', { page: 0, size: 10 })
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
		async fetchCustomerUserHistory() {
			const userHistoryData = this.$store.getters.getCustomerUserHistoryInfo;
			await this.$store
				.dispatch('GET_CUSTOMER_USER_HISTORY_INFO', {
					page: userHistoryData.page,
					size: userHistoryData.size,
				})
				.catch((res) => this.observeErrCode(res.data.errorCode, res.data.errorMessage, this.$toasted.show));
		},
	},
};
</script>

<style scoped></style>
