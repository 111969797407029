<template>
	<Fragment>
		<div class="header">
			<!-- // .lay_header -> .header -->
			<h1 class="heading-3-b">사용자 등록</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
				close
			</button>
		</div>
		<!--  contents-wrap  -->
		<div class="contents-wrap">
			<ul class="inp-list">
				<li>
					<div class="group-inp" :class="emailClass">
						<label class="label asterisk">이메일 주소</label>
						<input
							type="text"
							v-model="email"
							class=""
							placeholder="해당 메일 주소로 초대장이 전송됩니다."
							@input="[checkEmail(email) ? (emailClass = 'valid') : (emailClass = 'invalid')]"
						/>
					</div>
					<p class="text-detail invalid" v-if="emailClass === 'invalid'">
						<i class="ic-16 ic-invalid"></i>
						올바른 이메일 형식이 아닙니다.
					</p>
				</li>
				<li>
					<div class="group-inp">
						<label class="label asterisk">권한</label>
						<div class="select">
							<select v-model="role" disabled>
								<option v-for="(item, index) in typeList" :key="index" :value="item">{{ item }}</option>
							</select>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<!--//content end-->
		<div class="">
			<div class="btn-area group-btn">
				<button class="line" @click="handleClose">취소</button>
				<button class="solid" @click="inviteCustomerUser">사용자 등록 및 초대</button>
			</div>
		</div>
	</Fragment>
</template>

<script>
import mixinsErrCodeObserver from '@/components/exception/mixinsErrCodeObserver';
import { validation } from '@/components/apply/mixinsValidation';

export default {
	props: ['modalName'],
	mixins: [mixinsErrCodeObserver, validation],
	data() {
		return {
			email: '',
			role: '',
			typeList: [],
			emailClass: '',
		};
	},
	created() {
		// this.typeList = this.$store.state.apply.customerUserInfo.typeList;
		// disabled를 풀고 다른 타입으로 선택하지 못하게 하기 위해서 임시로 막았습니다.
		// 만약, 권한 설정이 가능해지면 위 코드 주석을 풀면 됩니다.
		this.typeList.push('ADMIN');
		this.role = this.typeList[0];
	},
	methods: {
		inviteCustomerUser(event) {
			let currentUser = this.$store.state.login.currentUser;
			if (this.email === currentUser.email) {
				this.$toasted.show('본인 계정으로 이메일을 전송할 수 없습니다.');
				return;
			}

			if (this.emailClass !== 'valid') {
				this.$toasted.show('이메일 형식이 맞지 않습니다. 다시 확인해주세요.');
				return;
			}

			const response = this.$store.dispatch('APPLY_CUSTOMER_USER', { email: this.email, role: this.role });
			response
				.then((res) => {
					res.data.applied
						? this.$toasted.show('초대 메일을 전송했습니다')
						: this.$toasted.show('초대 메일 전송에 실패했습니다');
					this.handleClose(event);
				})
				.catch((e) => {
					this.observeErrCode(e.data.errorCode, e.data.errorMessage, this.$toasted.show);
				});
		},
		handleClose(e) {
			e.preventDefault();
			this.$modal.hide(this.modalName);
		},
	},
};
</script>

<style scoped></style>
